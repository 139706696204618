import React from 'react';
import { PiWarningCircleBold } from 'react-icons/pi';


const ErrorComponent = ({ size }) => {
  return (
    <div>
        <div style={{display: "flex", justifyContent: "center"}}>
            <PiWarningCircleBold style={{ fontSize:"xx-large", textAlign:"center", alignSelf:"center", color:"#953337" }} />
        </div>
        <p style={{ fontSize:"medium", margin: "5px", fontWeight:"bold", textAlign:"center", color:"#953337" }}>Lütfen Tekrar Deneyiniz</p>
    </div>
  );
};

export default ErrorComponent;




