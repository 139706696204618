import data from '../data/categories.json'; // API entegrasyonu sonrasi commentle

const fetchCategories = async () => {
    try {
        // API entegrasyonuna kadar commented:
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/categories/?format=json`);
        // if (!response.ok) {
        //     return null;
        // }
        // const data = await response.json();
        return data
    } catch (error) {
        console.error(error);
    }
}

export default fetchCategories;
