import React from 'react';
import styles from './BrandItem.module.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';
import Rate from '../Rate/Rate';

const color_list = ['#FFC24C', '#F88600', '#A70B12']

function ChildItem({ index, child, highlighted }) {

    const borderColor = highlighted ? "red" : "E5E5E5";

    return (
        <Col key={index} xs={4} md={2} style={{
            display: 'flex',
            flexDirection: 'column',
            margin: "4px",
            padding: "1px",
            border: `1px solid ${borderColor}`,
            backgroundColor: "white",
        }}>
            <Row style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: "50px"
            }}>
                <Image src={child.logoUrl} className={`${styles.brandLogo}`} />
            </Row>
            <Row style={{
                textAlign: 'center',
            }}>
                <div style={{
                    color: "#3f3f46",
                    fontSize: "8pt",
                    fontWeight: "bold",
                    marginTop: "3px"
                }}
                >
                    {child.name}
                </div>
            </Row>
        </Col>)
}

function BrandItem({ item, highlightedChildren, otherChildren }) {

    const rateColor = color_list[item.rate - 1]

    const children = highlightedChildren.concat(otherChildren);

    return (
        <Link to={`/brand/${item.key}`} style={{ textDecoration: 'none', color: 'black' }}>
            <Container fluid className={`${styles.brand}`} style={{
                borderTop: `3px solid ${rateColor}`,
            }}>
                <Row className={`${styles.brandRow}`}>
                    <Col xs={3} md={2} className={`${styles.brandLogoContainer}`}>
                        <Image src={item.logoUrl} className={`${styles.brandLogo}`} />
                    </Col>
                    <Col xs={5} md={2}>
                        <span className={`${styles.brandName}`}>{item.name}</span>
                    </Col>
                    <Col md={5} className={`${styles.explanationContainer}`}>
                        <p className={`${styles.explanation}`}>
                            {item.explanation}
                        </p>
                    </Col>
                    <Col xs={4} md={3} className={`${styles.rateContainer}`}>
                        <Rate rate={item.rate} align='flex-end' />
                    </Col>
                </Row>
                {children.length > 0 &&
                    <React.Fragment>
                        <hr
                            style={{
                                width: '100%',
                                margin: 0,
                                border: 0,
                                borderBottom: '2px solid #C9C9C9'
                            }} />
                        <div
                            style={{
                                fontSize: "9pt",
                                color: "#858A99",
                                fontWeight: "bold",
                                marginTop: "0.5rem",
                                marginLeft: "1rem",
                                marginBottom: "0.5rem"
                            }}
                        >Alt Markalar ({children.length})</div>
                        <Container style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            padding: "0px",
                        }}>
                            <IoIosArrowBack />
                            <Container className={`${styles.childBrandsScroll}`}>
                                {
                                    <React.Fragment>
                                        {
                                            highlightedChildren.map((child, index) => (
                                                <ChildItem key={index} child={child} highlighted={true} />
                                            ))
                                        }
                                        {
                                            otherChildren.map((child, index) => (
                                                <ChildItem key={index} child={child} highlighted={false} />
                                            ))
                                        }
                                    </React.Fragment>

                                }
                            </Container>
                            <IoIosArrowForward />
                        </Container>
                    </React.Fragment>
                }
                <Container className={styles.showDetailsContainer}>
                    <span className={styles.showDetailsSpan} style={{
                        color: "#FFF",
                        backgroundColor: rateColor,
                    }}>
                        Detayları Göster
                    </span>
                </Container>


            </Container>
        </Link>
    );
}

export default BrandItem;