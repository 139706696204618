import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Container, Modal, Button, Row, Col, Image } from "react-bootstrap";

import Rate from "../Rate/Rate";

import logo from '../../img/bt_logo.png';

import { AiOutlineExport } from "react-icons/ai";
import { ImAttachment } from "react-icons/im";
import { IoIosInformationCircleOutline } from "react-icons/io";

import styles from "./BrandModal.module.css";

import BrandImageModal from "../BrandImageModal/BrandImageModal";
import { Link } from "react-router-dom";

function BrandModal({
    show,
    onHide,
    brand,
}) {

    function getTimeDifference(timestamp) {
        const currentTimestamp = Date.now();
        const diffMilliseconds = currentTimestamp - new Date(timestamp).getTime();
        const diffMinutes = Math.floor(diffMilliseconds / 60000);
        const diffHours = Math.floor(diffMinutes / 60);
        const diffDays = Math.floor(diffHours / 24);
        const diffMonths = Math.floor(diffDays / 30);

        if (diffMinutes < 60) {
            return `${diffMinutes} Dakika`;
        } else if (diffHours < 24) {
            return `${diffHours} Saat`;
        } else if (diffDays < 30) {
            return `${diffDays} Gün`;
        } else {
            return `${diffMonths} Ay`;
        }
    }

    const [imageModalShow, setImageModalShow] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                animation={false}
                fullscreen="md-down"
                centered
                className="rounded-0"
                style={{ fontFamily: "Poppins" }}
            >
                <Modal.Header closeButton>
                    <Container className="d-flex justify-content-center align-items-center">
                        <img alt="" src={logo} style={{ width: "auto", height: "40px", padding: 5 }} />
                        <span style={{ marginLeft: "5px", color: "#933038", fontWeight: 800, fontSize: "16px" }}>
                            <span>Bilinçli Tüketim Hareketi</span><br />
                        </span>

                    </Container>
                </Modal.Header>
                <Modal.Body>
                    <Container className={{margin: 0, padding:0}}>
                        <Row className="d-flex justify-content-center align-items-center mb-4">
                            <Col className="d-flex justify-content-center align-items-center " md={2} xs={3}>
                                <img src={brand.logoUrl} alt={brand.name} style={{
                                    maxHeight: "50px",
                                    maxWidth: "100%",
                                }} />
                            </Col>
                            <Col className="d-flex justify-content-start align-items-center" md={7} xs={5}>
                                <h3 className="m-0 fw-bold">{brand.name}</h3 >
                            </Col>
                            <Col className="d-flex justify-content-end align-items-center p-0 m-0" md={3} xs={4}>
                                <Rate rate={brand.rate} align={"flex-end"} width={"28%"} />
                            </Col>
                        </Row>

                        <Row className="d-flex justify-content-center align-items-center ">
                            <Col className="d-flex justify-content-center align-items-center" xs={12}>
                                <p className="m-0">{brand.explanation}</p>
                            </Col>
                        </Row>

                        {
                            brand.parent_id !== null &&
                            <React.Fragment>
                                <hr className="my-4" />
                                <Row className="d-flex justify-content-center align-items-center">
                                    <Col className="d-flex justify-content-center align-items-center " xs={4}>Detaylı bilgi için bakınız:</Col>
                                    <Col>
                                        <Link to={`/brand/${brand.parent.key}`}>
                                            <div className={`${styles.parentBrandDiv}`}>
                                                <img src={brand.parent.logoUrl} alt={brand.parent.name} style={{
                                                    maxHeight: "50px",
                                                    maxWidth: "100%",
                                                    marginRight: "20px",
                                                    marginLeft: "10px",
                                                }} />
                                                <h3 className="m-0 fw-bold">{brand.parent.name}</h3 >
                                            </div>
                                        </Link>
                                    </Col>

                                </Row>
                                <hr className="my-4" />

                            </React.Fragment>
                        }

                        {brand.children.length > 0 &&
                            <React.Fragment>

                                <Row>
                                    <div
                                        style={{
                                            color: "#858A99",
                                            fontWeight: "bold",
                                            marginTop: "1rem",
                                            marginBottom: "0.5rem"
                                        }}
                                    >Alt Markalar ({brand.children.length})</div>
                                </Row>

                                <Row className="d-flex justify-content-start">
                                    {
                                        brand.children.map((child, index) => (

                                            <Col
                                                key={index}
                                                className="d-flex justify-content-center align-items-center"
                                                xs={3}
                                                lg={2}
                                                style={{ flexDirection: "column" }}
                                            >
                                                {/* <Link to={`/brand/${child.id}`} style={{ textDecoration: "none" }}> */}
                                                <Container
                                                    className="m-1 p-1 border rounded-1"
                                                >
                                                    <Row style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        height: "50px"
                                                    }}>
                                                        <img src={child.logoUrl} alt={child.name} style={{
                                                            maxHeight: "90%",
                                                            width: "auto",
                                                        }} />
                                                    </Row>
                                                    <Row style={{
                                                        textAlign: 'center',
                                                    }}>
                                                        <div style={{
                                                            color: "#3f3f46",
                                                            fontSize: "8pt",
                                                            fontWeight: "bold",
                                                            marginTop: "3px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                        >
                                                            {child.name}
                                                        </div>
                                                    </Row>
                                                </Container>
                                                {/* </Link> */}
                                            </Col>
                                        ))
                                    }
                                </Row>

                            </React.Fragment>
                        }

                        {brand.other_links.length + brand.image_links.length > 0 &&
                            <React.Fragment>
                                <Row>
                                    <div
                                        style={{
                                            color: "#858A99",
                                            fontWeight: "bold",
                                            marginTop: "1rem",
                                            marginBottom: "0.5rem"
                                        }}
                                    >
                                        Kaynaklar
                                    </div>
                                </Row>

                                <Row className="d-flex justify-content-start">
                                    {
                                        brand.other_links.map((source, index) => {
                                            function getFirstGroup(regexp, str) {
                                                const array = [...str.matchAll(regexp)];
                                                return array.map(m => m[1]);
                                            }
                                            const source_site = getFirstGroup(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/img, source)[0].replace("-", "");
                                            return <Col
                                                key={index}
                                                xs={4}
                                                lg={2}
                                                className="p-0"
                                            >
                                                <a
                                                    href={source}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "#3f3f46",
                                                    }}
                                                >
                                                    <Row className={styles.sourceRow}>
                                                        <Col xs={4} style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            height: "100%",
                                                            padding: 0,
                                                        }}>
                                                            <AiOutlineExport
                                                                style={{
                                                                    color: "white",
                                                                    fontSize: "1.5rem",
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col xs={8} style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            flexDirection: "column",
                                                            alignItems: "start",
                                                            height: "100%",
                                                            padding: "0 10px",
                                                        }}>
                                                            <Row>Kaynak {index + 1}</Row>
                                                            <Row>
                                                                <span className={styles.sourceItem}>
                                                                    {source_site}
                                                                </span>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </a>
                                            </Col>
                                        }
                                        )
                                    }
                                    {
                                        brand.image_links.map((source, index) => {

                                            return <Col
                                                key={index}
                                                xs={4}
                                                lg={2}
                                                className="p-0"
                                                onClick={() => {
                                                    setImageModalShow(true);
                                                    setImageIndex(index);
                                                }}
                                            >
                                                <Row className={styles.sourceRow}>
                                                    <Col xs={4} style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: "100%",
                                                        padding: 0,
                                                    }}>
                                                        <ImAttachment
                                                            style={{
                                                                color: "white",
                                                                fontSize: "1.5rem",
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={8} style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        flexDirection: "column",
                                                        alignItems: "start",
                                                        height: "100%",
                                                        padding: "0 10px",
                                                    }}>
                                                        <Row>Görsel {index + 1}</Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        }
                                        )
                                    }
                                </Row>


                            </React.Fragment>
                        }

                        <Row>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    color: "#858A99",
                                    fontSize: "10pt",
                                    marginTop: "1rem",
                                    marginBottom: "0.5rem"
                                }}
                            >
                                <IoIosInformationCircleOutline style={{
                                    fontSize: "1rem",
                                    marginRight: "5px",
                                }} />
                                {getTimeDifference(brand.date_added)} Önce Eklendi.</div>
                        </Row>


                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide} style={{ backgroundColor: "#A00000", border: 0, marginRight: "5px", paddingRight: 20, paddingLeft: 20 }}>Kapat</Button>
                </Modal.Footer>
            </Modal>

            <BrandImageModal show={imageModalShow} onHide={() => setImageModalShow(false)} url={brand.image_links[imageIndex]} />
        </>
    );
}

export default BrandModal;