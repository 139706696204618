import React, { useState } from 'react';
import styles from './InfoBar.module.css'
import './Modals.css'
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { TbArrowsSort } from 'react-icons/tb';
import { HiOutlineAdjustmentsHorizontal } from 'react-icons/hi2';

const InfoBar = ({
    allCategories,
    categoriesSelected,
    setCategoriesSelected,
    rateSortDirection,
    setRateSortDirection,
    brandSortDirection,
    setBrandSortDirection
}) => {

    const [showSortModal, setShowSortModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    return (
        <Container fluid className={`${styles.infoBarContainer}`}>
            <Row className={`${styles.infoRow}`}>
                <Col className={`${styles.infoCol}`}>
                    <Button
                        variant="outline-secondary"
                        className={`${styles.infoButton}`}
                        onClick={() => setShowFilterModal(true)}
                    >
                        <HiOutlineAdjustmentsHorizontal className={`${styles.infoButtonIcon}`} />
                        <span className={`${styles.infoButtonText}`}>Filtrele</span>
                    </Button>
                    <Modal show={showFilterModal} centered animation={false} onHide={() => setShowFilterModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Kategoriler</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{padding:"15px 30px"}}>
                            {allCategories.sort(function (a, b) {
                                const collator = new Intl.Collator('tr', { sensitivity: 'base' });
                                return collator.compare(a.name, b.name);
                            }).map((option) => (
                                <div key={option.id} className="categoryCheckbox">
                                    <Form.Check
                                        key={option.id}
                                        type="checkbox"
                                        label={option.name}
                                        checked={categoriesSelected[option.id]}
                                        onChange={() => {
                                            setCategoriesSelected((prevValues) => ({
                                                ...prevValues,
                                                [option.id]: !prevValues[option.id],
                                            }));
                                        }}
                                        style={{ fontSize: '14pt' }}
                                    />
                                </div>
                            ))}
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col className={`${styles.infoCol}`}>
                    <Button variant="outline-secondary"
                        className={`${styles.infoButton}`}
                        onClick={() => setShowSortModal(true)}>
                        <TbArrowsSort className={`${styles.infoButtonIcon}`} />
                        <span className={`${styles.infoButtonText}`}>Sırala</span>
                    </Button>
                    <Modal show={showSortModal} centered animation={false} onHide={() => setShowSortModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Sırala</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span style={{
                                // color: '#A00000',
                                fontWeight: 'bold',
                            }}>Marka Sıralaması</span>
                            <hr style={{
                                padding: '0',
                                margin: '4px 0 10px 0',
                                height: '2px',
                                backgroundColor: '#000',
                                color: '#000',
                                borderColor: '#000',
                            }}/>
                            <label style={{ marginBottom: '10px' }}>
                                <input
                                    color='red'
                                    type="radio"
                                    name="radioBrandOptions"
                                    value="az"
                                    checked={brandSortDirection === 'az'}
                                    onChange={() => setBrandSortDirection("az")}
                                    className="sortRadio"
                                />
                                <span style={{ marginLeft: '8px' }}>Alfabetik A-Z</span>
                            </label>
                            <br />
                            <label style={{ marginBottom: '10px' }}>
                                <input
                                    color='red'
                                    type="radio"
                                    name="radioBrandOptions"
                                    value="za"
                                    checked={brandSortDirection === 'za'}
                                    onChange={() => setBrandSortDirection("za")}
                                    className="sortRadio"
                                />
                                <span style={{ marginLeft: '8px'}}>Alfabetik Z-A</span>
                            </label>
                            <br />
                            <label style={{ marginBottom: '10px' }}>
                                <input
                                    color='red'
                                    type="radio"
                                    name="radioBrandOptions"
                                    value="first"
                                    checked={brandSortDirection === 'first'}
                                    onChange={() => setBrandSortDirection("first")}
                                    className="sortRadio"
                                />
                                <span style={{ marginLeft: '8px'}}>İlk Eklenen Başta</span>
                            </label>
                            <br />
                            <label style={{ marginBottom: '10px' }}>
                                <input
                                    color='red'
                                    type="radio"
                                    name="radioBrandOptions"
                                    value="last"
                                    checked={brandSortDirection === 'last'}
                                    onChange={() => setBrandSortDirection("last")}
                                    className="sortRadio"
                                />
                                <span style={{ marginLeft: '8px'}}>Son Eklenen Başta</span>
                            </label>
                            <br />

                            <span style={{
                                // color: '#A00000',
                                fontWeight: 'bold',
                            }}>Derece Sıralaması</span>
                            <hr style={{
                                padding: '0',
                                margin: '4px 0 10px 0',
                                height: '2px',
                                backgroundColor: '#000',
                                color: '#000',
                                borderColor: '#000',
                            }}/>
                            <label style={{ marginBottom: '10px' }}>
                                <input
                                    color='red'
                                    type="radio"
                                    name="radioOptions"
                                    value="high"
                                    checked={rateSortDirection === 'high'}
                                    onChange={() => setRateSortDirection("high")}
                                    className="sortRadio"
                                />
                                <span style={{ marginLeft: '8px' }}>Yüksekten Düşüğe</span>
                            </label>
                            <br />
                            <label style={{ marginBottom: '10px' }}>
                                <input
                                    color='red'
                                    type="radio"
                                    name="radioOptions"
                                    value="low"
                                    checked={rateSortDirection === 'low'}
                                    onChange={() => setRateSortDirection("low")}
                                    className="sortRadio"
                                />
                                <span style={{ marginLeft: '8px'}}>Düşükten Yükseğe</span>
                            </label>
                            <br />
                            <label style={{ marginBottom: '10px' }}>
                                <input
                                    color='red'
                                    type="radio"
                                    name="radioOptions"
                                    value="none"
                                    checked={rateSortDirection === 'none'}
                                    onChange={() => setRateSortDirection("none")}
                                    className="sortRadio"
                                />
                                <span style={{ marginLeft: '8px'}}>Derece Sıralamasını Kaldır</span>
                            </label>
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>
        </Container>

    );
};

export default InfoBar;
