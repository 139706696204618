import data from '../data/brands.json' // API entegrasyonu sonrasi commentle

const fetchBrands = async () => {
    try {
        // API entegrasyonuna kadar commented:
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/brands/?format=json`);
        // if(!response.ok){
        //   return null;
        // }
        // const data = await response.json();
        data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            } else if (a.name > b.name) {
              return 1;
            } else {
              return 0;
            }
        });
        /*
        this pushes the child brands with show_as_child true to the top so that expanding will look smooth
        */
        data.sort((a, b) => (a.show_as_child === b.show_as_child ? 0 : a.show_as_child ? -1 : 1));
        return data
    } catch (error) {
        console.error(error);
    }
}

export default fetchBrands;
