import React from "react";
import { Container } from "react-bootstrap";
import styles from "./Footer.module.css";
import logo from '../../img/bt_logo.png';
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { IoMailOutline } from "react-icons/io5";

const Footer = () => {

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <span
            className={`${styles.bgContainer}`}
        >
            <Container fluid style={{ display: "flex", flexDirection: "row", padding:0 }}>
                <Container className={`${styles.footerLeft}`}>
                    <img alt="" src={logo} style={{ width: "auto", height: "60px", paddingRight: 5, paddingTop: 5, paddingBottom: 5 }} />
                    <span style={{ marginLeft: "5px", color: "white", fontWeight: 800, fontSize: "18px" }}>
                        <span>Bilinçli Tüketim</span><br />
                        <span>Hareketi</span>
                    </span>
                </Container>
                <Container className={`${styles.footerRight}`}>
                    <Container className={`${styles.footerSocialMedia}`}>
                        <FaInstagram
                            style={{ fontSize: "25px", color: "white", margin: "10px", cursor: "pointer" }}
                            onClick={() => openInNewTab("https://www.instagram.com/bilinclituketimcom/")}
                        />
                        <FaXTwitter
                            style={{ fontSize: "25px", color: "white", margin: "10px", cursor: "pointer" }}
                            onClick={() => openInNewTab("https://twitter.com/bilincli_tuket")}
                        />
                        <a href="mailto:bilinclituketimcom@gmail.com">
                            <IoMailOutline
                                style={{ fontSize: "25px", color: "white", margin: "10px", cursor: "pointer" }}
                            />
                        </a>

                    </Container>
                    <Container className='d-none d-md-flex justify-content-end p-0 text-white mb-1'>
                        <a href="/gizlilik-politikasi" style={{color:'inherit', textDecoration:'none'}}>
                            Gizlilik Politikası
                        </a>
                    </Container>
                    <Container className={`${styles.footerAllRightsReserved}`}>
                        &copy; {new Date().getFullYear()} Tüm Hakları Saklıdır.
                    </Container>
                </Container>
            </Container>
        </span>
    );
};

export default Footer;