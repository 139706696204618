import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingSpinner = ({ size }) => {
  return (
    <div>
      <Spinner animation="grow" size={size} style={{ margin: "5px", color: "#A70B12" }} />
      <Spinner animation="grow" size={size} style={{ margin: "5px", color: "#F88600" }} />
      <Spinner animation="grow" size={size} style={{ margin: "5px", color: "#FFC24C" }} />
      <p style={{ fontSize:"medium", margin: "5px", fontWeight:"bold", textAlign:"center", color:"#5A5858" }}>Yükleniyor...</p>
    </div>
  );
};

export default LoadingSpinner;
