import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import BrandList from '../../components/BrandList/BrandList'; // Replace with the actual path to your component
import AppBar from '../../components/AppBar/AppBar';
import InfoBar from "../../components/InfoBar/InfoBar";
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import ErrorComponent from '../../components/Common/ErrorComponent'
import "./App.css"

import useHome from "../../hooks/useHome";
import { Container } from "react-bootstrap";
import SearchBar from "../../components/SearchBar/SearchBar";

import { IoClose } from "react-icons/io5";
import Footer from "../../components/Footer/Footer";

import BrandModal from "../../components/BrandModal/BrandModal";

const Home = () => {
    const {
        loading,
        error,
        allCategories,
        categoriesSelected,
        setCategoriesSelected,
        selectedCategoriesList,
        rateSortDirection,
        setRateSortDirection,
        brandSortDirection,
        setBrandSortDirection,
        searchText,
        setSearchText,
        filteredBrands,
        currentBrand,
        handleCloseModal,
    } = useHome()

    return (
        <>
            <Container fluid className="mainContainer">
                {loading || error ? <AppBar /> : <></>}
                {loading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 0, marginTop: "100px" }}>
                        <LoadingSpinner />
                    </div>
                ) :
                    <>
                        {error ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 0, marginTop: "50px" }}>
                                <ErrorComponent />
                            </div>
                        ) :
                            <Container fluid className="homeContainer">
                                <Container fluid className="m-0 p-0 appbarSearchInfo">
                                    <AppBar />
                                    <SearchBar
                                        searchText={searchText}
                                        setSearchText={setSearchText}
                                    />
                                    <InfoBar
                                        allCategories={allCategories}
                                        categoriesSelected={categoriesSelected}
                                        setCategoriesSelected={setCategoriesSelected}
                                        rateSortDirection={rateSortDirection}
                                        setRateSortDirection={setRateSortDirection}
                                        brandSortDirection={brandSortDirection}
                                        setBrandSortDirection={setBrandSortDirection}
                                    />


                                    {selectedCategoriesList.length > 0 &&
                                        <React.Fragment>
                                            <Container fluid
                                                className="categoryContainer"
                                            >
                                                {selectedCategoriesList.sort(function (a, b) {
                                                    const collator = new Intl.Collator('tr', { sensitivity: 'base' });
                                                    return collator.compare(a.name, b.name);
                                                }).map((category) => (
                                                    <div key={category.id}
                                                        className="categoryItem"
                                                    >
                                                        <IoClose
                                                            className="categoryCloseButton"
                                                            onClick={() => {
                                                                setCategoriesSelected((prevValues) => ({
                                                                    ...prevValues,
                                                                    [category.id]: false,
                                                                }));
                                                            }}
                                                        />
                                                        <span className="categoryText">
                                                            {category.name}
                                                        </span>
                                                    </div>
                                                ))}
                                            </Container>

                                            <hr style={{ width: '100%', margin: 0, border: 0, borderBottom: '1px solid #D9D9D9' }} />
                                        </React.Fragment>

                                    }
                                </Container>

                                <BrandList items={filteredBrands} searchText={searchText} />
                            </Container>
                        }
                    </>
                }
            </Container>
            <Footer />

            <BrandModal
                show={currentBrand.id !== null}
                onHide={() => handleCloseModal()}
                brand={currentBrand}
            />
        </>

    );
}

export default Home;
