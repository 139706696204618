import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home/Home';
import SiteDocument from './pages/SiteDocument/SiteDocument';
import Contact from './pages/Contact/Contact';
import Criteria from './pages/Criteria/Criteria';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/brand/:key" element={<Home />} />
        <Route path="/hakkimizda" element={<SiteDocument id={0} />} />
        <Route path="/gizlilik-politikasi" element={<SiteDocument id={1} />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/kriterlerimiz" element={<Criteria/>} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);