import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from '../../img/bt_logo.png';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoInformationCircleOutline, IoMailOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { MdQuestionMark } from "react-icons/md";
import watermelon from '../../img/watermelon.svg';

function AppBarMobile() {

    const [show, setShow] = React.useState(false);

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <>
            <Nav className="d-flex d-lg-none justify-content-between align-items-center" activeKey="/home" style={{ height: "65px", backgroundColor: '#f6f2ec' }}>
                <Nav.Item style={{ width: "50px", textAlign: "center" }}>
                    <RxHamburgerMenu style={{ fontSize: "25" }} onClick={() => setShow(true)} />
                </Nav.Item>
                <Nav.Item onClick={() => window.location = '/'} style={{ display: "flex", alignItems: "center" }}>
                    <img alt="" src={logo} style={{ width: "auto", height: "50px", padding: 5 }} />
                    <span style={{ marginLeft: "5px", color: "#933038", fontWeight: 800, fontSize: "15px" }}>
                        <span>Bilinçli Tüketim</span><br />
                        <span>Hareketi</span>
                    </span>
                </Nav.Item>
                <Nav.Item style={{ width: "50px", textAlign: "center" }} />
            </Nav>

            <Offcanvas show={show} onHide={() => setShow(false)} className="text-bg-dark">
                <Container className="d-flex flex-align-start flex-column h-100 w-100 ">

                    <Container className='d-flex justify-content-end mt-3'>
                        <RxCross2 onClick={() => setShow(false)} style={{ fontSize: "20px" }} />
                    </Container>
                    <Offcanvas.Header>
                        <Container onClick={() => { window.location = "/" }} className='d-flex justify-content-start align-items-center mt-2'>
                            <img alt="" src={logo} style={{ width: "auto", height: "70px", padding: 5 }} />
                            <span style={{ marginLeft: "10px", color: "#FFFFFF", fontWeight: 800, fontSize: "20px" }}>
                                <span>Bilinçli Tüketim</span><br />
                                <span>Hareketi</span>
                            </span>
                        </Container>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="flex-column" style={{ fontWeight: 500, fontSize: "15pt" }}>
                            <Container onClick={() => { window.location = "/" }} className='my-3 d-flex align-items-center'>
                                {<img src={watermelon} style={{
                                    color: "#FFFFFF",
                                    width: "20px",
                                }} />}&nbsp; Anasayfa
                            </Container>
                            <Container onClick={() => { window.location = "/kriterlerimiz" }} className='my-3 d-flex align-items-center'>
                                <MdQuestionMark />&nbsp; Kriterlerimiz
                            </Container>
                            <Container onClick={() => { window.location = "/hakkimizda" }} className='my-3 d-flex align-items-center'>
                                <IoInformationCircleOutline />&nbsp; Hakkımızda
                            </Container>
                            {/* <Container className='my-3 d-flex align-items-center'>
                            <IoCreateOutline />&nbsp; Blog
                        </Container> */}
                            <Container onClick={() => { window.location = "/iletisim" }} className='my-3 d-flex align-items-center'>
                                <IoMailOutline />&nbsp; İletişim
                            </Container>
                        </Nav>
                    </Offcanvas.Body>
                    <Container className='d-flex justify-content-center'>
                        <FaInstagram
                            style={{ fontSize: "25px", color: "white", margin: "10px", cursor: "pointer" }}
                            onClick={() => openInNewTab("https://www.instagram.com/bilinclituketimcom/")}
                        />
                        <FaXTwitter
                            style={{ fontSize: "25px", color: "white", margin: "10px", cursor: "pointer" }}
                            onClick={() => openInNewTab("https://twitter.com/bilincli_tuket")}
                        />
                        <a href="mailto:bilinclituketimcom@gmail.com">
                            <IoMailOutline
                                style={{ fontSize: "25px", color: "white", margin: "10px", cursor: "pointer" }}
                            />
                        </a>
                    </Container>
                    <Container className='d-flex justify-content-center mb-2'>
                        <a href="/gizlilik-politikasi" style={{textDecoration:'none', color:'inherit'}}>
                            Gizlilik Politikası
                        </a>
                    </Container>
                </Container>

            </Offcanvas>
        </>
    );
}

export default AppBarMobile;