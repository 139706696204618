import React from 'react';
import { Container } from 'react-bootstrap';
import BrandItem from '../BrandItem/BrandItem';
import styles from './BrandList.module.css';

import search from '../../services/searchText';

const BrandList = ({ items, searchText }) => {

    function getChildrenLists(item) {
        const children = items.filter(child => child.parent_id === item.id);

        if(searchText === ''){
            return [[], children]
        }

        let highlightedChildren = children.filter(child => search(searchText, child));
        highlightedChildren.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

        const otherChildren = children.filter(child => !search(searchText, child));

        return [highlightedChildren, otherChildren]
    }

    return (
        <Container fluid className={`${styles.brandListContainer}`}>
            {items.map((item, index) => {
                if (item.parent_id === null) {
                    const [highlightedChildren, otherChildren] = getChildrenLists(item);
                    return (
                        <BrandItem
                            key={item.id}
                            item={item}
                            highlightedChildren={highlightedChildren}
                            otherChildren={otherChildren}
                        />
                    )
                }
                else{
                    return null;
                }
            })}
        </Container>
    );
};

export default BrandList;
