import React from 'react';

import AppBarFullscreen from './AppBarFullscreen';
import AppBarMobile from './AppBarMobile';

const AppBar = () => {

  return (
    <>
      <AppBarFullscreen/>
      <AppBarMobile/>
    </>
  );
};

export default AppBar;
