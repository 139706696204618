import React from 'react';
import Container from 'react-bootstrap/Container';
import { AiFillCloseSquare } from "react-icons/ai";
import { rate_color_map } from '../../assets/colors';

function Rate({ rate, align="center", width="25%", maxHeight="70px" }) {

    return (
        <Container style={{
            alignItems: "center",
            justifyContent: align,
            display: "flex",
            height: "100%",
            width: "100%",
            padding: "0px",
            margin: "0px",
        }} className="p-0">
            {
                rate_color_map[rate]['rate_color'].map((color, index) => (
                    <AiFillCloseSquare
                        key={index}
                        style={{
                            color: color,
                            width: width,
                            height: "auto",
                            maxHeight: maxHeight,
                            padding: 0,
                            margin: 0,
                        }}
                    />
                ))
            }
        </Container>

    );
}

export default Rate;