import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import AppBar from '../../components/AppBar/AppBar';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import ErrorComponent from '../../components/Common/ErrorComponent'
import styles from './Criteria.module.css';
import "../Home/App.css"

import { Container, Row, Col } from "react-bootstrap";

import Footer from "../../components/Footer/Footer";
import fetchRates from "../../services/fetchRates";
import Rate from '../../components/Rate/Rate';

const Criteria = () => {

    const [rates, setRates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const data = await fetchRates();
            if (data === null) {
                setError(true);
                setLoading(false);
                return;
            }
            setRates(data.sort(function (a, b) {
                return b.rate - a.rate;
            }));
            setLoading(false);
        }
        fetchData();
    }
        , [])

    return (
        <>
            <Container fluid className="mainContainer">
                <Container fluid className={styles.appbarContainer}>
                    <AppBar />
                </Container>
                {loading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
                        <LoadingSpinner />
                    </div>
                ) :
                    <>
                        {error ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
                                <ErrorComponent />
                            </div>
                        ) :
                            <>
                                <Container
                                    fluid
                                    className={styles.header}
                                >
                                    <h1 className={styles.headerTitle}>
                                        Değerlendirme Kriterlerimiz
                                    </h1>
                                </Container>

                                <div className={styles.criteriaContainer}>
                                    {rates.map((rate, index) => (
                                        <React.Fragment key={index}>
                                            <Row key={index} className={styles.rateHeaderContainer}>
                                                <Col xs={3} lg={1} className={styles.rateSign}>
                                                    <Rate rate={rate.rate} align='flex-start' width="32%" maxHeight="90px" />
                                                </Col>
                                                <Col xs={9} lg={11} className={styles.rateName}>
                                                    <h3 className={styles.rateNameText}>{rate.name}</h3>
                                                </Col>
                                            </Row>
                                            <ul className="m-0">
                                                    {
                                                        rate.criteria.map((criterion, index) => (
                                                            <li key={index} className={styles.criterion}>
                                                                <span className="fw-light">{criterion}</span>
                                                            </li>
                                                        ))
                                                    }
                                            </ul>
                                            <hr/>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </>
                        }
                    </>
                }
            </Container >
            <Footer />
        </>
    );
}

export default Criteria;
