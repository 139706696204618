import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import AppBar from '../../components/AppBar/AppBar';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import ErrorComponent from '../../components/Common/ErrorComponent'
import styles from './SiteDocument.module.css';
import "../Home/App.css"

import { Container } from "react-bootstrap";

import Footer from "../../components/Footer/Footer";
import fetchAboutUs from "../../services/fetchAboutUs";

import data from '../../data/site_documents.json'; 

const fetchSiteDocument = async (index) => {
    try {

        return data[index]
    } catch (error) {
        console.error(error);
    }
}

const SiteDocument = ({id}) => {

    const [content, setContent] = useState("<div></div>");
    const [header, setHeader] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const data = await fetchSiteDocument(id);
            if (data === null) {
                setError(true);
                setLoading(false);
                return;
            }
            setContent(data.body);
            setHeader(data.title);
            setLoading(false);
        }
        fetchData();
    }
        , [])

    return (
        <>
            <Container fluid className="mainContainer">
                <Container fluid className={styles.appbarContainer}>
                    <AppBar />
                </Container>
                {loading ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
                        <LoadingSpinner />
                    </div>
                ) :
                    <>
                        {error ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "50px" }}>
                                <ErrorComponent />
                            </div>
                        ) :
                            <React.Fragment>
                                <span
                                    className={styles.header}
                                >
                                    <h1 className={styles.headerTitle}>
                                        {header}
                                    </h1>

                                </span>
                                <div className={styles.contentContainer} dangerouslySetInnerHTML={{ __html: content }} />

                            </React.Fragment>
                        }
                    </>
                }
            </Container>
            <Footer />
        </>
    );
}

export default SiteDocument;
