import data from "../data/rates.json"; // API entegrasyonu sonrasi commentle

const fetchRates = async () => {
    try {
        /*
        Simdilik burayı commentliyorum. Sonrasında entegre edilecek.
        
        const response = await fetch(`${process.env.REACT_APP_API_URL}/rates/?format=json`);
        if(!response.ok){
          return null;
        }
        const data = await response.json();
        */

        return data
    } catch (error) {
        console.error(error);
    }
}

export default fetchRates;
