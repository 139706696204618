
export const sendFeedback = async ({
    userEmail,
    messageHeader,
    message
}) => {

    const requestUrl = `${process.env.REACT_APP_API_URL}/user-feedback/`

    const requestBody = {
        'email' : userEmail,
        'header' : messageHeader,
        'content' : message
    }

    const headers = {
        'Authorization': `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
        'Content-Type': 'application/json'
    }

    const response = await fetch(requestUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody)
    })

    console.log("slvhksvbskbv")

    return response

}