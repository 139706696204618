import React from "react";
import { Button, InputGroup, Container } from "react-bootstrap";
import { IoSearch, IoClose } from "react-icons/io5";
import styles from "./SearchBar.module.css";
import './SearchBar.css'

const SearchBar = ({searchText, setSearchText}) => {
    return (
        <Container
            fluid
            className={`${styles.bgContainer}`}
        >
            <Container
                className={`${styles.searchContainer}`}
            >

                <InputGroup className={`${styles.searchInputGroup}`}>
                    <input
                        type="text"
                        placeholder="Marka ara..."
                        aria-describedby="basic-addon2"
                        className={`form-control ${styles.searchInput}`}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Button
                        id="basic-addon2"
                        className={`${styles.searchButton}`}
                    >
                        {
                            searchText.length > 0 ?
                                <IoClose className={`${styles.searchIcon}`} onClick={() => setSearchText("")} />
                                :
                                <IoSearch className={`${styles.searchIcon}`} />
                        }
                    </Button>
                </InputGroup>
            </Container>
        </Container>
    );
};

export default SearchBar;