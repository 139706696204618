// function that allows one mismatch
// function search(txtToSearch, srcTxt) {
//     const txt = txtToSearch.toLowerCase();
//     const src = srcTxt.toLowerCase();
//     // generate regex for all possibilities. for this case, it will generate "d?.?og|do?.?g|dog?.?" -> double .? are for 1 char insertion 
//     var re = new RegExp(txt.split('').map(function (a, b, c) { return '^' + txt.substr(0, b) + a + '?.?' + txt.substr(b + 1); }).join('|'), 'gi');
//     return src.match(re) != null
// }

function search(txtToSearch, brand) {
    const txt = txtToSearch.toLowerCase().trim();

    const brandName = brand.name.toLowerCase();
    let otherNames = [];
    if ("other_names" in brand) {
        otherNames = brand.other_names.map((name) => name.toLowerCase());
    }

    const allNames = [brandName, ...otherNames];

    let found = false;
    for (let i = 0; i < allNames.length; i++) {
        const name = allNames[i];
        if (name.startsWith(txt)) {
            found = true;
            break;
        }
    }

    return found;
}

export default search;