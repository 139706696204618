import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../img/bt_logo.png';

import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { IoMailOutline } from "react-icons/io5";

function AppBarFullscreen() {

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <Navbar collapseOnSelect style={{ backgroundColor: '#f6f2ec'}} className={`d-none d-lg-block p-0`}>
            <Container fluid style={{
                padding: "3px 5%",
            }}>
                <Navbar.Brand href="/" style={{ display: "flex", alignItems: "center", }}>
                    <img alt="" src={logo} style={{ width: "auto", height: "60px", paddingRight: 5, paddingTop: 5, paddingBottom: 5 }} />
                    <span style={{ marginLeft: "5px", color: "#933038", fontWeight: 800, fontSize: "16px" }}>
                        <span>Bilinçli Tüketim</span><br />
                        <span>Hareketi</span>
                    </span>
                </Navbar.Brand>

                <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link className='mx-2' href="/">Anasayfa</Nav.Link>
                    <Nav.Link className='mx-2' href="/hakkimizda">Hakkımızda</Nav.Link>
                    <Nav.Link className='mx-2' href="/kriterlerimiz">Kriterlerimiz</Nav.Link>
                    <Nav.Link className='ms-2' href="/iletisim">İletişim</Nav.Link>
                    <Nav.Link className='ms-2'>
                        <FaInstagram
                            style={{ fontSize: "25px", cursor: "pointer" }}
                            onClick={() => openInNewTab("https://www.instagram.com/bilinclituketimcom/")}
                        />
                    </Nav.Link>
                    <Nav.Link className='ms-1'>
                        <FaXTwitter
                            style={{ fontSize: "25px", cursor: "pointer" }}
                            onClick={() => openInNewTab("https://twitter.com/bilincli_tuket")}
                        />
                    </Nav.Link>
                    <Nav.Link className='ms-1' href="mailto:bilinclituketimcom@gmail.com" target='_blank'>
                        <IoMailOutline
                            style={{ fontSize: "25px", cursor: "pointer" }}
                        />
                    </Nav.Link>
                    {/* <Container>
                        <FaInstagram
                            style={{ fontSize: "25px", color: "white", margin: "10px", cursor: "pointer" }}
                            onClick={() => openInNewTab("https://www.instagram.com/bilinclituketimcom/")}
                        />
                        <FaXTwitter
                            style={{ fontSize: "25px", color: "white", margin: "10px", cursor: "pointer" }}
                            onClick={() => openInNewTab("https://twitter.com/bilincli_tuket")}
                        />
                        <a href="mailto:bilinclituketimcom@gmail.com">
                            <IoMailOutline
                                style={{ fontSize: "25px", color: "white", margin: "10px", cursor: "pointer" }}
                            />
                        </a>

                    </Container> */}
                </Nav>
            </Container>
        </Navbar>
    );
}

export default AppBarFullscreen;