import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Container, Modal, Button, Row, Col, Image } from "react-bootstrap";

import logo from '../../img/bt_logo.png';

function BrandImageModal({
    show,
    onHide,
    url,
}) {

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            animation={false}
            fullscreen="md-down"
            centered
            className="rounded-0"
            style={{ fontFamily: "Poppins" }}
        >
            <Modal.Header closeButton>
                <Container className="d-flex justify-content-center align-items-center">
                    <img alt="" src={logo} style={{ width: "auto", height: "40px", padding: 5 }} />
                    <span style={{ marginLeft: "5px", color: "#933038", fontWeight: 800, fontSize: "16px" }}>
                        <span>Bilinçli Tüketim Hareketi</span><br />
                    </span>

                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col className="d-flex justify-content-center align-items-center">
                            <Image src={url} style = {{width: "100%"}}/>
                        </Col>
                    </Row>

                </Container>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} style={{ backgroundColor: "#A00000", border: 0 }}>Geri</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default BrandImageModal;