import { useState, useEffect, useLayoutEffect } from "react";
import fetchBrands from "../services/fetchBrands";
import fetchCategories from "../services/fetchCategories";
import search from "../services/searchText";
import { useNavigate, useParams } from "react-router-dom";

const useHome = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [allCategories, setAllCategories] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState({});
    const [selectedCategoriesList, setSelectedCategoriesList] = useState([]);

    const [rateSortDirection, setRateSortDirection] = useState("high"); // low, none 
    const [brandSortDirection, setBrandSortDirection] = useState("az"); // az, za, first, last

    const [searchText, setSearchText] = useState('');

    const [allBrands, setAllBrands] = useState([]);

    const [filteredBrands, setFilteredBrands] = useState([]);

    const [moveToHome, setMoveToHome] = useState(false);

    const key = useParams().key;

    const defaultBrand = {
        id: null,
        key: null,
        name: "",
        logoUrl: "",
        explanation: "",
        image_links: [],
        other_links: [],
        rate: 3,
        categories: [],
        children: [],
        parent_id: null,
        other_names: [],
        show_as_child: false,
        date_added: "",
        date_updated: "",
    };

    const [currentBrand, setCurrentBrand] = useState(defaultBrand);

    function modalContent(key, allBrands) {
        if (key === null) {
            setCurrentBrand(defaultBrand);
            return;
        }
        if (!(allBrands.map((brand) => brand.key).includes(key))) {
            setCurrentBrand(defaultBrand);
            navigate("/");
            return;
        }

        const brand = allBrands.find((brand) => brand.key === key);

        const childrenBrands = allBrands.filter((child) => child.parent_id === brand.id)

        brand.children = childrenBrands;

        if (brand.parent_id !== null) {
            const parentBrand = allBrands.find((parent) => parent.id === brand.parent_id);
            brand.parent = parentBrand;
        }

        setCurrentBrand(brand);
    }

    useEffect(() => {
        if (moveToHome) {
            modalContent(key, allBrands);
        }
    }, [key, allBrands]);

    useEffect(() => {
        async function fetchData() {
            const brandsData = await fetchBrands();
            const categoriesData = await fetchCategories();
            if (brandsData === null || categoriesData === null) {
                setError(true);
                setLoading(false);
                return;
            }
            const sortedBrands = sortData(brandsData, rateSortDirection, brandSortDirection);

            if (process.env.REACT_APP_SAVE_IMAGES === "true") {
                sortedBrands.forEach((brand) => {
                    brand.logoUrl = brand.logoUrl.replace("https://bilinclituketim.s3.us-east-005.backblazeb2.com/uploads", "/img/content/logo").replace("https://f005.backblazeb2.com/file/bilinclituketim", "/img/content");
                    const saved_image_links = brand.image_links.map((image_link) => (
                        image_link.replace("https://bilinclituketim.s3.us-east-005.backblazeb2.com/uploads", "/img/content/proof").replace("https://f005.backblazeb2.com/file/bilinclituketim", "/img/content")
                    ))
                    brand.image_links = saved_image_links
                });
            }

            setFilteredBrands(sortedBrands);
            setAllBrands(sortedBrands);
            setAllCategories(categoriesData);
            setCategoriesSelected(categoriesData.reduce((acc, category) => {
                acc[category.id] = false;
                return acc;
            }, {}));
            setError(false);
            setLoading(false);
            setMoveToHome(true);
            modalContent(key, sortedBrands);
        }
        fetchData();

    }, []);

    function filterBrands(brands) {
        const noFilterSelected = Object.values(categoriesSelected).reduce((acc, category) => acc && !category, true);

        if (noFilterSelected) {
            return brands;
        }

        let categoriesSelectedList = Object.keys(categoriesSelected).filter((category) => categoriesSelected[category]);

        categoriesSelectedList = categoriesSelectedList.map((category) => parseInt(category));
        const filtered = brands.filter((brand) => {
            return brand.categories.some(category => categoriesSelectedList.includes(category));
        });
        return filtered;
    }

    function applySearchText(brands, searchText) {

        if (searchText === '') {
            return brands;
        }

        let searchResults = []

        let parentBrands = brands.filter((brand) => brand.parent_id === null);

        for (let i = 0; i < parentBrands.length; i++) {
            const brand = parentBrands[i];

            let otherNames = []

            if ("other_names" in brand) {
                otherNames = brand.other_names;
            }

            if (search(searchText, brand)) {
                searchResults.push(brand);
                searchResults.push(...brands.filter((child) => child.parent_id === brand.id));
            }
            else {
                const children = brands.filter((child) => child.parent_id === brand.id);
                let addParent = false;
                for (let j = 0; j < children.length; j++) {
                    const child = children[j];
                    let otherNames = []
                    if ("other_names" in brand) {
                        otherNames = child.other_names;
                    }
                    if (search(searchText, child)) {
                        addParent = true;
                        break;
                    }
                }
                if (addParent) {
                    searchResults.push(brand);
                    searchResults.push(...children);
                }
            }
        }

        return searchResults
    }


    useEffect(() => {
        const noFilterSelected = Object.values(categoriesSelected).reduce((acc, category) => acc && !category, true);

        if (noFilterSelected) {
            setSelectedCategoriesList([]);

            const results = applySearchText(allBrands, searchText);
            setFilteredBrands(results);
            return;
        }
        else {
            let categoriesSelectedList = Object.keys(categoriesSelected).filter((category) => categoriesSelected[category]);

            setSelectedCategoriesList(categoriesSelectedList.map((category) => ({
                id: parseInt(category),
                name: allCategories.find((cat) => cat.id === parseInt(category)).name
            })));

            const filtered = filterBrands(allBrands);
            const results = applySearchText(filtered, searchText);
            setFilteredBrands(results);

        }
    }, [categoriesSelected]);

    useEffect(() => {
        setFilteredBrands(sortData(filteredBrands, rateSortDirection, brandSortDirection));
    }, [rateSortDirection, brandSortDirection]);

    useEffect(() => {
        if (searchText === '') {
            const filtered = filterBrands(allBrands);
            setFilteredBrands(filtered);
        }
        else {
            const filtered = filterBrands(allBrands);
            const results = applySearchText(filtered, searchText);
            setFilteredBrands(results);
        }

    }, [searchText]);

    const sortData = (data, rateSortOption, brandSortOption) => {
        const sortedData = [...data];
        sortedData.sort((a, b) => {

            if (rateSortOption === 'high') {
                if (brandSortOption === "az") {
                    if (a.rate === b.rate) {
                        return a.name.localeCompare(b.name);
                    }
                }
                else if (brandSortOption === "za") {
                    if (a.rate === b.rate) {
                        return b.name.localeCompare(a.name);
                    }
                }
                else if (brandSortOption === "first") {
                    if (a.rate === b.rate) {
                        return a.id - b.id;
                    }
                }
                else if (brandSortOption === "last") {
                    if (a.rate === b.rate) {
                        return b.id - a.id;
                    }
                }
                return b.rate - a.rate;
            }
            else if (rateSortOption === 'low') {
                if (brandSortOption === "az") {
                    if (a.rate === b.rate) {
                        return a.name.localeCompare(b.name);
                    }
                }
                else if (brandSortOption === "za") {
                    if (a.rate === b.rate) {
                        return b.name.localeCompare(a.name);
                    }
                }
                else if (brandSortOption === "first") {
                    if (a.rate === b.rate) {
                        return a.id - b.id;
                    }
                }
                else if (brandSortOption === "last") {
                    if (a.rate === b.rate) {
                        return b.id - a.id;
                    }
                }
                return a.rate - b.rate;
            }
            else {
                if (brandSortOption === "az") {
                    return a.name.localeCompare(b.name);
                }
                else if (brandSortOption === "za") {
                    return b.name.localeCompare(a.name);
                }
                else if (brandSortOption === "first") {
                    return a.id - b.id;
                }
                else if (brandSortOption === "last") {
                    return b.id - a.id;
                }
            }
        });
        return sortedData;
    };

    const handleCloseModal = () => {
        navigate("/");
    };

    return {
        loading,
        error,
        allCategories,
        categoriesSelected,
        setCategoriesSelected,
        selectedCategoriesList,
        rateSortDirection,
        setRateSortDirection,
        brandSortDirection,
        setBrandSortDirection,
        searchText,
        setSearchText,
        filteredBrands,
        currentBrand,
        handleCloseModal,
    }

}

export default useHome;