export const colors = {
    backgroundColor : '#f6f2ec',
    proofItemBackgroungColor: '#64748B',
    redBoycott: '#A70B12',
    orangeBoycott: '#F88600',
    yellowBoycott: '#FFC24C',
    categoryItemColor: '#586C85',
}

export const rate_color_map = {
    1: { 'rate_color': ['#FFC24C', '#C3C3C3', '#C3C3C3'] },
    2: { 'rate_color': ['#F88600', '#F88600', '#C3C3C3'] },
    3: { 'rate_color': ['#A70B12', '#A70B12', '#A70B12'] },
};